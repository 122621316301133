import React from 'react';

import { AppStateContext } from '../../contexts/app-state-provider.context';

export default class PatientInfoPage extends React.Component {
  static contextType = AppStateContext;
  componentDidMount() {
    const language = this.context.getLanguage();
    if (language === 'french') {
      window.location.replace('https://www.rochecanada.com/PMs_Fr/vabysmo/Vabysmo_PM_CIF.pdf');
    } else {
      window.location.replace('https://www.rochecanada.com/PMs/vabysmo/Vabysmo_PM_CIE.pdf');
    }
  }

  render() {
    return <></>;
  }
}
